import BaseModel from "@/models/base-model.js";

export default class ClothPriceType extends BaseModel {
  fields = {
    name: {
      type: "text",
      title: "Nama",
      label: "nama",
      minLen: 3,
      validationRules: {
        required: true,
        minLength: true,
      },
    },
    description: {
      type: "textarea",
      title: "Deskripsi",
      label: "description",
    },
  };
  endpoint = process.env.VUE_APP_API_BASE_URL + "cloth-price-types";
}
