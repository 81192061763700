<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import clothPriceType from "@/models/cloth-price-type.js";
import detailGroupInput from "@/components/detail-group-input.vue";
import loadingVue from "@/components/skeleton.vue";

const clothPriceTypeModel = new clothPriceType();

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, detailGroupInput, loadingVue },
  page: {
    title: "Detail Produk Kategori",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: "Kain",
          href: "/",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      isLoading: false,
      setData: [],
      columns: [
        {
          value: "name",
          label: "Nama",
        },
        {
          value: "description",
          label: "Deskripsi",
        },
        {
          value: "created_by.name",
          label: "Dibuat Oleh",
        },
        {
          value: "updated_at",
          label: "Tanggal di Perbarui",
        },
        {
          value: "created_at",
          label: "Tanggal di Buat",
        },
      ],
      rowCount: 5,
    };
  },
  watch: {
    "$route.params.id": function(val) {
      if (val !== undefined) {
        this.getDetail();
      }
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.isLoading = true;
      clothPriceTypeModel
        .find(this.$route.params.id)
        .then((resp) => {
          this.setData = resp;
          this.isLoading = false;
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
          this.isLoading = false;
        });
    },
    returnBack() {
      return this.$router.go(-1);
      // return this.$router.push("/produk/warna");
    },
  },
  middleware: "authentication",
};
</script>
<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <b-row v-if="!isLoading">
          <detail-group-input :columns="columns" :data="setData" />
          <router-link to="/produk/tipe-harga">
            <b-btn
              variant="success"
              class="ml-auto text-white m-3"
              style="
              width: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
            "
              ><i class="uil-arrow-left"></i> Kembali
            </b-btn>
          </router-link>
        </b-row>
        <b-row v-else>
          <b-col class="my-3" sm="6" md="4" v-for="row in rowCount" :key="row">
            <loadingVue
              class="my-2"
              width="50%"
              height="2rem"
              borderRadius="7px"
            />
            <loadingVue width="100%" height="3rem" borderRadius="7px" />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </Layout>
</template>
